import { axiosNoAuth } from './axiosAuth'

const register = (email: string, password: string) => {
  return axiosNoAuth
    .post('/auth/register', { email, password })
    .then(response => {
      if (response && response.data) {
        return response
      }
    })
    .catch(error => {
      throw error.response.data.errors
    })
}

export default register
