import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Create, Toolbar, SimpleForm, FileInput, FileField } from 'react-admin'
import CustomSaveButton from './CustomSaveButton'

const useStyles = makeStyles(
  theme => ({
    formWrapper: {
      width: '30rem',
      maxWidth: '90%',
      display: 'flex',
      'flex-direction': 'column',
    },
  }),
  { name: 'RaLoginForm' },
)

const CreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <CustomSaveButton {...props} />
  </Toolbar>
)

export const InvitationCreate = (props = {}) => {
  const classes = useStyles()

  return (
    <Create
      {...props}
      title={
        <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
          INVITATION: CREATE
        </h5>
      }
    >
      <SimpleForm toolbar={<CreateToolbar />} variant='standard'>
        <div className={classes.formWrapper}>
          <FileInput source='file' label='User CSV' accept='.csv'>
            <FileField source='src' title='title' />
          </FileInput>
        </div>
      </SimpleForm>
    </Create>
  )
}
