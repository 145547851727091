import React from 'react'
import { useFormState } from 'react-final-form'
import { BooleanInput } from 'react-admin'

// this switch is always disabled when it's false
export const TFASwitch = () => {
  const state = useFormState()

  return (
    <BooleanInput
      disabled={!state.values.tfaEnabled}
      label='Two-Factor-Authentication'
      source='tfaEnabled'
    />
  )
}
