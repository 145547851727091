import React from 'react'
import { Edit, TextInput, TabbedForm, FormTab, BooleanInput } from 'react-admin'
import CustomEditToolBar from './CustomEditToolBar'
import { InterestSelect } from './InterestSelect'
import { makeStyles } from '@material-ui/core/styles'
import { ListSelect } from './ListSelect'

const useStyles = makeStyles(
  theme => ({
    formWrapper: {
      width: '30rem',
      maxWidth: '90%',
      display: 'flex',
      'flex-direction': 'column',
    },
  }),
  { name: 'SubscriptionForm' },
)

export const SubscriptionEdit = (props = {}) => {
  const classes = useStyles()

  return (
    <Edit
      {...props}
      title={
        <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
          NEWSLETTER: EDIT
        </h5>
      }
    >
      <TabbedForm variant='standard' toolbar={<CustomEditToolBar />}>
        <FormTab label='Main'>
          <div className={classes.formWrapper}>
            <ListSelect />
            <InterestSelect isEdit />
            <TextInput
              source='name.en'
              variant='standard'
              label='Name: English'
              fullWidth
            />
            <TextInput
              source='name.ar'
              variant='standard'
              label='Name: Arabic'
              fullWidth
            />
            <TextInput
              source='description.en'
              multiline
              variant='standard'
              label='Description: English'
              fullWidth
            />
            <TextInput
              source='description.ar'
              multiline
              variant='standard'
              label='Description: Arabic'
              fullWidth
            />
            <BooleanInput label='Active' source='active' />
          </div>
        </FormTab>
        {/*<FormTab label='Icon'>
          <ImageInput
            source='editIcon'
            label='Newsletter Group Icon'
            placeholder={
              <p>
                Drop a picture or click here to change the Newsletter Group Icon
              </p>
            }
            accept='image/*'
          >
            <ImageField source='icon' title='title' />
          </ImageInput>
          </FormTab>*/}
      </TabbedForm>
    </Edit>
  )
}
