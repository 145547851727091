import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Edit,
  TabbedForm,
  FormTab,
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  TextInput,
  DateInput,
  SelectInput,
} from 'react-admin'
import { LocationSelect } from '../users/LocationSelect'
import { MemberSelect } from './MemberSelect'
import CustomToolBar from './CustomEditToolBar'
import coreBusiness from './constants/corebusiness_en.json'
import funding from './constants/funding_en.json'
import marketStage from './constants/marketstage_en.json'
import industrySector from './constants/industrysector_en.json'
import mainTechnology from './constants/maintechnology_en.json'
import { PendingSelectField } from './PendingSelectField'

const useStyles = makeStyles(
  theme => ({
    formWrapper: {
      width: '30rem',
      maxWidth: '90%',
      display: 'flex',
      'flex-direction': 'column',
    },
  }),
  { name: 'RaLoginForm' },
)

export const OrganizationEdit = (props = {}) => {
  const classes = useStyles()
  return (
    <Edit
      {...props}
      title={
        <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
          ORGANISATION: EDIT
        </h5>
      }
    >
      <TabbedForm variant='standard' toolbar={<CustomToolBar />}>
        <FormTab label='Profile'>
          <div className={classes.formWrapper}>
            <TextInput source='name' fullWidth variant='standard' />
            <TextInput source='description' multiline variant='standard' />
            <TextInput source='website' variant='standard' />

            <LocationSelect />
            <MemberSelect field='members' label='Members' />
            <MemberSelect field='admins' label='Admins' />
            <PendingSelectField
              field='pendingRequests'
              label='Pending Requests'
            />
            <SelectInput
              source='coreBusiness'
              choices={coreBusiness}
              optionValue='code'
              variant='standard'
            />
            <NumberInput source='fullTimeEmployees' variant='standard' />
            <DateInput
              label='Incorporation Date'
              source='incorporateDate'
              variant='standard'
            />
            <SelectInput
              allowEmpty
              label='Market Stage'
              source='marketStage'
              choices={marketStage}
              optionValue='code'
              variant='standard'
            />
            <SelectInput
              allowEmpty
              source='funding'
              choices={funding}
              optionValue='code'
              variant='standard'
            />
            <SelectInput
              allowEmpty
              label='Industry Sector'
              source='industrySector'
              choices={industrySector}
              optionValue='code'
              variant='standard'
            />
            <SelectInput
              allowEmpty
              label='Main Technology'
              source='mainTechnology'
              choices={mainTechnology}
              optionValue='code'
              variant='standard'
            />
          </div>
        </FormTab>
        <FormTab label='Tags'>
          <div className={classes.formWrapper}>
            <ArrayInput source='tags' variant='standard'>
              <SimpleFormIterator>
                <TextInput variant='standard' label='' />
              </SimpleFormIterator>
            </ArrayInput>
          </div>
        </FormTab>
        <FormTab label='Social Media'>
          <div className={classes.formWrapper}>
            <ArrayInput source='socialMedia' variant='standard'>
              <SimpleFormIterator>
                <TextInput label='Url' source='url' />
                <TextInput label='Type' source='type' />
              </SimpleFormIterator>
            </ArrayInput>{' '}
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
