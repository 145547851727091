import React from 'react'
import CustomSaveButton from './CustomSaveButton'
import { InterestSelect } from './InterestSelect'
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  BooleanInput,
} from 'react-admin'
import { ListSelect } from './ListSelect'

const CreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <CustomSaveButton {...props} isCreate />
  </Toolbar>
)
export const SubscriptionCreate = (props = {}) => (
  <Create
    {...props}
    title={
      <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
        NEWSLETTER: CREATE
      </h5>
    }
  >
    <SimpleForm toolbar={<CreateToolbar />} variant='standard'>
      <ListSelect />
      <InterestSelect />
      <TextInput source='name.en' label='Name: English' />
      <TextInput source='name.ar' label='Name: Arabic' />
      <TextInput
        multiline
        source='description.en'
        label='Description: English'
      />
      <TextInput
        multiline
        source='description.ar'
        label='Description: Arabic'
      />

      <BooleanInput label='Active' source='active' />
    </SimpleForm>
  </Create>
)
