import React, { useEffect } from 'react'
import providerClient from 'data-provider'
// @ts-ignore
import { Admin, Resource, Layout } from 'react-admin'
import UsersIcon from '@material-ui/icons/Person'
import OrganizationIcon from '@material-ui/icons/Business'
import NewsletterIcon from '@material-ui/icons/Announcement'
import MailIcon from '@material-ui/icons/Mail'
import AppsIcon from '@material-ui/icons/Apps'

import { UserList, UserEdit, UserCreate } from '../users'
import {
  OrganizationList,
  OrganizationEdit,
  OrganizationCreate,
} from '../organizations'
import { loadToken } from '../../services/TokenService'
import CustomAppBar from '../../components/CustomAppBar'
import theme from '../theme'
import authProvider from './authProvider'
import { getMyOwnUserId } from '../VerifyUserService'
import { GET_ONE } from 'ra-core'
import {
  SubscriptionList,
  SubscriptionEdit,
  SubscriptionCreate,
} from '../subscriptions'
import { ClientAppList, ClientAppEdit, ClientAppCreate } from '../client-apps'
import {
  InvitationList,
  InvitationCreate,
  InvitationShow,
} from '../invitations'

const CustomLayout = (props: any) => <Layout {...props} appBar={CustomAppBar} />

const Home: React.FC = () => {
  const token = loadToken()
  const url = process.env.REACT_APP_PROVIDER_URL + '/admin'
  const dataProvider = providerClient(url || '', token || '')
  useEffect(() => {
    getMyOwnUserId().then(id => {
      return dataProvider(GET_ONE, 'users', { id: id })
        .then(
          // legitimately could return ANY thing including null
          // or weird errors
          (res: any) => {
            if (!res || !res.data || res.data.role !== 'admin') {
              alert(
                'Logged in user does not have admin role or the token expired!',
              )
              localStorage.clear()
              window.location.reload()
            }
          },
        )
        .catch(err => {
          alert('Logged in user does not have admin role or the token expired!')
          localStorage.clear()
          window.location.reload()
        })
    })
  }, [dataProvider])
  return (
    <Admin
      appLayout={CustomLayout}
      dataProvider={dataProvider}
      theme={theme}
      authProvider={authProvider}
    >
      <Resource
        name='users'
        options={{ label: 'USERS' }}
        icon={UsersIcon}
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
      />
      <Resource
        name='organisations'
        options={{ label: 'ORGANISATIONS' }}
        icon={OrganizationIcon}
        list={OrganizationList}
        edit={OrganizationEdit}
        create={OrganizationCreate}
      />
      <Resource
        name='newsletters'
        options={{ label: 'NEWSLETTERS' }}
        icon={NewsletterIcon}
        list={SubscriptionList}
        edit={SubscriptionEdit}
        create={SubscriptionCreate}
      />
      <Resource
        name='external-clients'
        options={{ label: 'EXTERNAL CLIENTS' }}
        icon={AppsIcon}
        list={ClientAppList}
        edit={ClientAppEdit}
        create={ClientAppCreate}
      />
      <Resource
        name='invitations'
        options={{ label: 'INVITATIONS' }}
        icon={MailIcon}
        list={InvitationList}
        show={InvitationShow}
        create={InvitationCreate}
      />
    </Admin>
  )
}

export default Home
