/* eslint-disable max-lines */
import * as React from 'react'
import { useCallback } from 'react'
import {
  Modal,
  Card,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import {
  SaveButton,
  useCreate,
  useRedirect,
  useNotify,
  useUpdate,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopy from '@material-ui/icons/FileCopy'

const useStyles = makeStyles(
  theme => ({
    card: {
      width: '30rem',
      maxWidth: '80%',
      margin: 'auto',
      position: 'relative',
      top: '30%',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
    },
    textField: {
      margin: '1rem 0rem',
    },
    button: {
      marginTop: '1rem',
    },
  }),
  { name: 'RaLoginForm' },
)

const CustomSaveButton = (props: { basePath: string; isCreate?: boolean }) => {
  const formState = useFormState()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const [create] = useCreate('external-clients')
  const [update] = useUpdate('external-clients', formState.values.id)
  const { basePath, isCreate } = props
  const [clientInfo, setClientInfo] = React.useState<
    | {
        id: string
        secret: string
      }
    | undefined
  >()
  const classes = useStyles()

  const handleDone = () => {
    setClientInfo(undefined)
    redirectTo(basePath)
  }

  const handleSave = useCallback(
    (values, redirect) => {
      const {
        name,
        contact,
        user,
        organisation,
        openid,
        validRedirectUris,
      } = values
      const filteredUserScopes = (user && Object.keys(user)) ?? []
      const filteredOrganisationScopes =
        (organisation && Object.keys(organisation)) ?? []
      const filteredOpenIdScopes = (openid && Object.keys(openid)) ?? []
      if (isCreate) {
        create(
          {
            payload: {
              data: {
                name,
                contact,
                validScopes: [
                  ...filteredUserScopes.map(key => `user.${key}`),
                  ...filteredOrganisationScopes.map(
                    key => `organisation.${key}`,
                  ),
                  ...filteredOpenIdScopes.map(key => `openid.${key}`),
                ],
                validRedirectUris:
                  validRedirectUris
                    ?.filter((val: { value: string }) => !!val?.value)
                    .map((val: { value: string }) => val.value) ?? [],
              },
            },
          },
          {
            onSuccess: ({ data }: any) => {
              notify('ra.notification.created', 'info', {
                // eslint-disable-next-line @typescript-eslint/camelcase
                smart_count: 1,
              })
              setClientInfo({ secret: data.secret, id: data.id })
            },
            onFailure: () => {
              notify('Could not create client app', 'warning')
            },
          },
        )
      } else {
        update(
          {
            payload: {
              data: {
                name,
                contact,
                validScopes: [
                  ...filteredUserScopes.map(key => `user.${key}`),
                  ...filteredOrganisationScopes.map(
                    key => `organisation.${key}`,
                  ),
                  ...filteredOpenIdScopes.map(key => `openid.${key}`),
                ],
                validRedirectUris:
                  validRedirectUris?.map(
                    (val: { value: string }) => val.value,
                  ) ?? undefined,
              },
            },
          },
          {
            onSuccess: ({ data }: any) => {
              notify('ra.notification.updated', 'info', {
                // eslint-disable-next-line @typescript-eslint/camelcase
                smart_count: 1,
              })
              redirectTo(basePath)
            },
            onFailure: () => {
              notify('Could not updated client app', 'warning')
            },
          },
        )
      }
    },
    [isCreate, create, notify, update, redirectTo, basePath],
  )

  return (
    <>
      <SaveButton {...props} onSave={handleSave} />
      <Modal
        disableBackdropClick
        open={!!clientInfo}
        onClose={() => setClientInfo(undefined)}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <Card className={classes.card}>
          <Typography variant='subtitle1' gutterBottom>
            Below are your Client ID and Client Secret. Store them somewhere
            safely. You will not be able to retrieve your secret later.
          </Typography>
          <div></div>
          <TextField
            className={classes.textField}
            value={clientInfo?.id}
            id='outlined-basic'
            label='Client ID'
            variant='outlined'
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <CopyToClipboard
                    text={clientInfo?.id}
                    onCopy={() => alert('copied!')}
                  >
                    <IconButton aria-label='copy to clipboard'>
                      {<FileCopy />}
                    </IconButton>
                  </CopyToClipboard>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.textField}
            value={clientInfo?.secret}
            id='outlined-basic'
            label='Client Secret'
            variant='outlined'
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <CopyToClipboard
                    text={clientInfo?.secret}
                    onCopy={() => alert('copied!')}
                  >
                    <IconButton aria-label='copy to clipboard'>
                      {<FileCopy />}
                    </IconButton>
                  </CopyToClipboard>
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={handleDone}
            className={classes.button}
            variant='contained'
          >
            Done
          </Button>
        </Card>
      </Modal>
    </>
  )
}

export default CustomSaveButton
