import React from 'react'
import roles from './constants/roles'
import UserRoleSwitch from './fields/UserRoleSwitch'
import FullNameField from './fields/FullNameField'
import {
  List,
  Datagrid,
  EmailField,
  BooleanField,
  Filter,
  TextInput,
  SelectInput,
  Pagination,
  downloadCSV,
} from 'react-admin'
import { IUser } from '../../models/IUser'
import jsonExport from 'jsonexport/dist'
import DeleteWithConfirmButton from '../../components/DeleteButton'
import ListActions from '../../components/ListActions'
import gender from './constants/gender'
import BulkActionButtons from './BulkActionButtons'
import { NationalitySelect } from './NationalitySelect'

const exporter = (users: IUser[]) => {
  const postsForExport = users.map(user => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, _id, ...userExport } = user // omit id
    return userExport
  })
  jsonExport(postsForExport, { rowDelimiter: ';' }, (err: any, csv: any) => {
    downloadCSV(csv, 'users') // download as 'posts.csv` file
  })
}

const UserFilter = (props = {}) => (
  <Filter {...props} variant='standard'>
    <TextInput label='Email Address' source='email' type='email' alwaysOn />
    <TextInput label='First Name' source='firstName' alwaysOn />
    <TextInput label='Last Name' source='lastName' alwaysOn />
    <TextInput label='Location' source='location' alwaysOn />
    <NationalitySelect
      label='Nationality'
      emptyText='All'
      allowEmpty
      alwaysOn
    />
    <SelectInput
      label='Gender'
      source='gender'
      emptyText='All'
      choices={gender}
      allowEmpty
      alwaysOn
    />

    <SelectInput
      label='Role'
      source='role'
      emptyText='All'
      allowEmpty
      choices={roles}
      alwaysOn
    />
  </Filter>
)

const UsersPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

export const UserList = (props = {}) => (
  <List
    actions={<ListActions />}
    title={
      <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>USERS</h5>
    }
    {...props}
    exporter={exporter}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<UserFilter />}
    pagination={<UsersPagination />}
    perPage={25}
    bulkActionButtons={<BulkActionButtons />}
  >
    <Datagrid rowClick='edit'>
      <FullNameField label='Name' sortBy='firstName' />
      <EmailField source='email' />
      <UserRoleSwitch source='role' roles={roles} />
      <BooleanField source='validated' />
      <DeleteWithConfirmButton
        entity='User'
        {...props}
        undoable={false}
        {...props}
      />
    </Datagrid>
  </List>
)
