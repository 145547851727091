export default [
  {
    id: 'user',
    name: 'User',
  },
  {
    id: 'admin',
    name: 'Admin',
  },
]
