import { axiosIdentity } from './axiosIdentity'
import { axiosNoAuth, axiosAuth } from './axiosAuth'
import { IUserObject, IUser } from '../../models/IUser'
import { AxiosResponse } from 'axios'
import { IListResponse } from 'data-provider/dist/interfaces'

export const getUser = (id: string) => {
  return axiosIdentity
    .get(`/users/${id}`)
    .then((response: AxiosResponse<IUser>) => response.data)
}

export const getUsersAdmin = (searchTerm: string) => {
  return axiosIdentity
    .get(`/admin/users?searchTerm=${searchTerm}`)
    .then((response: AxiosResponse<IListResponse>) => response.data)
}

export const validateSession = () => {
  return axiosAuth
    .post(`/auth/validatesession`)
    .then((response: any) => response.data)
}

export const checkEmail = (email: string) => {
  return axiosNoAuth
    .post(`/auth/checkEmail`, { email })
    .then((response: any) => response.data)
}

export const updateUser = (id: string, user: IUserObject) => {
  return axiosIdentity
    .patch(`/users/${id}`, user)
    .then((response: any) => response.data)
}

export const uploadPhoto = (id: string, payload: File) => {
  const formData = new window.FormData()
  formData.append('profilePicture', payload)
  return axiosIdentity
    .post(`/users/${id}/picture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)
}

export const deleteUser = (id: string) => {
  return axiosIdentity
    .delete(`/users/${id}`)
    .then((response: any) => response.data)
}

export const getOrganisations = (id: string) => {
  return axiosIdentity
    .get(`/users/${id}/organisations`)
    .then((response: any) => response.data)
}

export const getOtp = ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  return axiosNoAuth
    .post(`/tfa/enable`, { email, password })
    .then((response: any) => {
      if (response && response.data) {
        return response.data
      }
    })
    .catch((error: any) => {
      throw error.response.data.errors
    })
}

export const disableTfa = ({
  password,
  token,
}: {
  password: string
  token: string
}) => {
  return axiosAuth
    .post(`/tfa/disable`, { password, token })
    .then((response: any) => {
      if (response && response.data) {
        return response.data
      }
    })
    .catch((error: any) => {
      throw error.response.data.errors
    })
}

export const getQr = (otp: string) => {
  return axiosNoAuth
    .get(`/tfa/qrcode?qr_url=${otp}&qr_type=svg`, {
      responseType: 'arraybuffer',
    })
    .then((response: any) => {
      if (response && response.data) {
        return Buffer.from(response.data, 'binary').toString('base64')
      }
    })
    .catch((error: any) => {
      throw error.response.data.errors
    })
}

export const validateTwoFactorQr = ({
  email,
  token,
}: {
  email: string
  token: string
}) => {
  return axiosNoAuth
    .post(`/tfa/verify`, { email, token })
    .then((response: any) => {
      if (response && response.data) {
        return response.data
      }
    })
    .catch((error: any) => {
      throw error.response.data.errors
    })
}
