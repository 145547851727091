import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import roles from './constants/roles'
import CustomToolBar from './CustomEditToolBar'
import { TFASwitch } from './TFASwitch'
import ImgField from './ImgField'
import { LocationSelect } from './LocationSelect'
import { LinkedinInput } from './LinkedinInput'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  DateInput,
} from 'react-admin'

import education from './constants/education_en.json'
import { NationalitySelect } from './NationalitySelect'

const useStyles = makeStyles(
  (theme) => ({
    blueButton: {
      '& .ra-delete-button': {
        color: theme.palette.primary.main,
      },
    },
    formWrapper: {
      width: '30rem',
      maxWidth: '90%',
      display: 'flex',
      'flex-direction': 'column',
    },
  }),
  { name: 'RaLoginForm' },
)

export const UserEdit = (props = {}) => {
  const classes = useStyles()
  return (
    <Edit
      {...props}
      title={
        <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
          USER: EDIT
        </h5>
      }
      className={classes.blueButton}
    >
      <TabbedForm variant='standard' toolbar={<CustomToolBar />}>
        <FormTab label='PROFILE'>
          <div className={classes.formWrapper}>
            <SelectInput
              variant='standard'
              source='preferredLanguage'
              choices={[
                { id: 'lang_en', name: 'en' },
                { id: 'lang_ar', name: 'ar' },
              ]}
            />
            <TextInput source='email' variant='standard' />
            <TextInput source='firstName' variant='standard' />
            <TextInput source='lastName' variant='standard' />
            <ImgField />
            <NationalitySelect />
            <DateInput label='Date of Birth' source='dob' variant='standard' />
            <SelectInput
              source='gender'
              variant='standard'
              choices={[
                { id: 'm', name: 'male' },
                { id: 'f', name: 'female' },
              ]}
            />
            <SelectInput
              source='education'
              variant='standard'
              choices={education}
              optionValue='code'
            />
            <TextInput
              label='Mobile'
              source='mobileNumber'
              variant='standard'
            />
            <TextInput
              label='Biography'
              source='bio'
              multiline
              variant='standard'
            />
            <TextInput source='title' variant='standard' />
            <LinkedinInput />
            <TextInput label='Slogan' source='headline' variant='standard' />
            <LocationSelect />
            <SelectInput
              source='role'
              variant='standard'
              choices={roles}
              optionValue='id'
            />
          </div>
        </FormTab>
        <FormTab label='TAGS'>
          <div className={classes.formWrapper}>
            <ArrayInput source='tags' variant='standard'>
              <SimpleFormIterator>
                <TextInput variant='standard' label='' />
              </SimpleFormIterator>
            </ArrayInput>
          </div>
        </FormTab>
        <FormTab label='SETTINGS'>
          <BooleanInput source='validated' />
          <TFASwitch />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
