/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useState } from 'react'
import {
  useRedirect,
  useNotify,
  //@ts-ignore
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { apiService } from '../../services/api/apiService'
import DeleteIcon from '@material-ui/icons/Delete'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: '#f44336',
    },
  }),
)

const CustomDeleteButton = (props: { basePath: string; data: any }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath, data } = props

  const handleBtnClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleClick = useCallback(() => {
    setLoading(true)

    apiService.invitations
      .delete(data?.id)
      .then(() => {
        setLoading(false)
        notify('ra.notification.deleted', 'info', { smart_count: 1 })
        redirectTo(basePath)
      })
      .catch(err => {
        notify('Could not delete invitation', 'warning')
        setLoading(false)
      })
  }, [data, redirectTo, notify, basePath])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Delete Invitation List
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete the invitation list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={handleClick}
            startIcon={<DeleteIcon />}
            className={classes.button}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        className={classes.button}
        startIcon={<DeleteIcon />}
        {...props}
        onClick={handleBtnClick}
        disabled={isLoading}
      >
        DELETE
      </Button>
    </>
  )
}

export default CustomDeleteButton
