/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback } from 'react'

import Button from '@material-ui/core/Button'
import { apiService } from '../../services/api/apiService'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import FileSaver from 'file-saver'

const CustonDownloadButton = (props: { basePath: string; data: any }) => {
  const { data } = props

  const handleClick = useCallback(() => {
    apiService.invitations.download(data?.id).then(text => {
      // Create blob link to download
      const blob = new Blob([text], { type: 'text/csv;charset=utf-8' })
      FileSaver.saveAs(blob, data?.fileName)
    })
  }, [data])

  return (
    <>
      <Button startIcon={<DownloadIcon />} {...props} onClick={handleClick}>
        DOWNLOAD
      </Button>
    </>
  )
}

export default CustonDownloadButton
