/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  downloadCSV,
  TextInput,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import { IOrganisation } from '../../models/IOrganisation'
import { IMember } from '../../models/IUser'
import ListActions from '../../components/ListActions'

const exporter = (organisations: IOrganisation[]) => {
  const postsForExport = organisations.map(organisation => {
    const { id, _id, members, admins, ...organisationExport } = organisation // omit id
    const removeId = (member: IMember) => {
      const { id, ...memberWithoutId } = member
      return memberWithoutId
    }
    const membersWithoutId = members.filter(m => !!m).map(removeId)
    const adminsWithoutId = admins.filter(m => !!m).map(removeId)
    return {
      ...organisationExport,
      members: membersWithoutId,
      admins: adminsWithoutId,
    }
  })
  jsonExport(postsForExport, { rowDelimiter: ';' }, (err: any, csv: any) => {
    downloadCSV(csv, 'organisations') // download as 'posts.csv` file
  })
}

const OrganisationFilter = (props = {}) => (
  <Filter {...props} variant='standard'>
    <TextInput label='Name' source='name' alwaysOn />
    <TextInput label='Location' source='location' alwaysOn />
  </Filter>
)
export const OrganizationList = (props = {}) => (
  <List
    actions={<ListActions />}
    title={
      <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
        ORGANISATIONS
      </h5>
    }
    {...props}
    exporter={exporter}
    perPage={25}
    bulkActionButtons={false}
    filters={<OrganisationFilter />}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <TextField source='website' />
      <TextField source='location' />
      <TextField source='coreBusiness' />
      <TextField source='mainTechnology' />
      <TextField label='Market Stage' source='stageProduct' />
    </Datagrid>
  </List>
)
