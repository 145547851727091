import React, { useState, useEffect } from 'react'
import { OptionsType, ValueType } from 'react-select'
import AsyncSelect from 'react-select/async'
import { apiService } from '../../services/api/apiService'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      height: '68px',
      fontFamily: theme.typography.fontFamily,
      margin: '8px 0px 4px',
      width: '100%',
    },
    label: {
      marginBottom: 8,
      fontSize: 12,
      opacity: 0.54,
    },
  }),
  { name: 'MailchimpIdSelect' },
)

export const ListSelect = () => {
  const form = useForm()
  const formState = useFormState()
  const [currentVal, setCurrentVal] = useState<{
    value: string
    label: string
  } | null>(null)
  const classes = useStyles()

  const handleChange = (value: { value: string; label: string }) => {
    setCurrentVal(value)
    form.change('mailchimpListId', value.value)
  }

  useEffect(() => {
    apiService.newsletter.lists().then(data => {
      const curr = data.find(val => val.id === formState.values.mailchimpListId)
      if (curr) {
        setCurrentVal({ value: curr.id, label: curr.name })
      }
    })
  }, [formState.values.mailchimpListId])

  const loadOptions = async (
    searchString: string,
    _: (options: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    const data = await apiService.newsletter.lists()
    const filteredOptions = data.map(list => {
      return {
        value: list.id,
        label: list.name,
      }
    })
    return filteredOptions.filter(option =>
      option.label.includes(searchString.toLowerCase()),
    )
  }

  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>List</label>
      <AsyncSelect
        label='List'
        source='mailchimpListId'
        value={currentVal}
        cacheOptions
        onChange={
          handleChange as (
            value: ValueType<{ value: string; label: string }>,
          ) => void
        }
        loadOptions={loadOptions}
        defaultOptions
      />
    </div>
  )
}
