import React, { ChangeEvent, MouseEvent, useState } from 'react'
// @ts-ignore
import { useNotify, useDataProvider, UPDATE, Confirm } from 'react-admin'
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'

interface IProps {
  source: string
  record?: { [key: string]: string }
  label?: string
  roles: {
    id: string
    name: string
  }[]
}

const UserRoleSwitch = ({ source, record = {}, label, roles }: IProps) => {
  console.log(record, source)
  const [initialValue, setInitialValue] = useState(record ? record[source] : '')
  const [selectedValue, setSelectedValue] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleValueChange = (e: ChangeEvent<any>) => {
    if (e.target.value !== initialValue) {
      setSelectedValue(e.target.value)
      setOpenDialog(true)
    }
  }

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleValueChangeConfirm = () => {
    console.log(record, selectedValue)
    dataProvider(UPDATE, 'users', {
      id: record.id,
      data: { id: record.id, role: selectedValue },
    })
      .then(() => {
        setOpenDialog(false)
        setInitialValue(selectedValue)
        notify('User role has been changed')
      })
      .catch((err: any) => {
        if (err) {
          notify('User role could not be changed', 'warning')
        }
        setOpenDialog(false)
      })
  }

  return (
    <div onClick={handleClick}>
      <FormControl style={{ width: '100%' }}>
        {label && <InputLabel id='role-label'>{label}</InputLabel>}
        <Select
          labelId='role-label'
          value={initialValue}
          onChange={handleValueChange}
        >
          {roles.map(role => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Confirm
        isOpen={openDialog}
        title='Change Role'
        content='Are you sure you want to change the role of the user?'
        confirm='Change'
        confirmColor='primary'
        cancel='Cancel'
        onConfirm={handleValueChangeConfirm}
        onClose={handleCloseDialog}
      />
    </div>
  )
}

export default UserRoleSwitch
