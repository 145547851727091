import { axiosIdentity } from './axiosIdentity'

export const getOrganisation = (organisationId: string) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}`)
    .then(response => response.data)
}

export const updateOrganisation = (
  organisationId: string,
  field: string,
  value: any,
) => {
  return axiosIdentity
    .patch(`/organisations/${organisationId}`, { [field]: value })
    .then(response => response.data)
}

export const createOrganisation = (name: string) => {
  return axiosIdentity
    .post('/organisations', { name })
    .then(response => response.data)
}

export const deleteOrganisation = (organisationId: string) => {
  return axiosIdentity
    .delete(`/organisations/${organisationId}`)
    .then(response => response.data)
}

export const addOrganisationLogo = (organisationId: string, logo: File) => {
  const formData = new window.FormData()
  formData.append('logo', logo)

  return axiosIdentity
    .post(`/organisations/${organisationId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data)
}

export const getMembers = (organisationId: string) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}/members`)
    .then(response => response.data)
}
export const getOrganisationMember = (
  organisationId: string,
  userId: string,
) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}/members/${userId}`)
    .then(response => response.data)
}
export const addOrganisationMember = (
  organisationId: string,
  email: string,
) => {
  return axiosIdentity
    .post(`/organisations/${organisationId}/members`, { email })
    .then(response => response.data)
}
export const removeOrganisationMember = (
  organisationId: string,
  userId: string,
) => {
  return axiosIdentity
    .delete(`/organisations/${organisationId}/members/${userId}`)
    .then(response => response.data)
}

export const getOrganisationAdmins = (organisationId: string) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}/admins`)
    .then(response => response.data)
}
export const getOrganisationAdmin = (
  organisationId: string,
  userId: string,
) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}/admins/${userId}`)
    .then(response => response.data)
}
export const addOrganisationAdmin = (organisationId: string, email: string) => {
  return axiosIdentity
    .post(`/organisations/${organisationId}/admins`, { email })
    .then(response => response.data)
}
export const removeOrganisationAdmin = (organisationId: string) => {
  return axiosIdentity
    .delete(`/organisations/${organisationId}`)
    .then(response => response.data)
}

export const decideRequest = (
  requestId: string,
  accept: boolean,
  isAdmin?: boolean,
) => {
  return axiosIdentity
    .post(`/organisations/decideRequest/${requestId}`, {
      accept,
      isAdmin: isAdmin ?? false,
    })
    .then((response) => response.data)
}
