import { axiosNoAuth as axios } from './axiosAuth'

export const verifyCode = (email: string, verifyCode: string) => {
  return axios
    .post('/auth/verifyCode', { email, verificationCode: +verifyCode })
    .then(response => {
      if (response && response.data) {
        return response.data
      }
    })
    .catch(error => {
      throw error.response.data.errors
    })
}

export const verifyEmail = (token: string) => {
  return axios
    .get('/auth/verifyEmail', {
      headers: {
        token,
      },
    })
    .then(response => {
      if (response && response.data) {
        return response.data
      }
    })
    .catch(error => {
      throw error.response.data.errors
    })
}

export const resendVerifyEmail = (email: string) => {
  return axios
    .post('/auth/sendEmailVerificationEmail', { email })
    .then(response => {
      if (response && response.data) {
        return response.data
      }
    })
    .catch(error => {
      throw error.response.data.errors
    })
}
