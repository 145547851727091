import { loadToken } from '../services/TokenService'

const getValidateSessionUrl = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_AUTH_URL
  return `${apiUrl}/auth/validatesession`
}

const getHeader = () => ({
  'Content-Type': 'application/json',
  Authorization: loadToken(),
})

/*
 * returns something like:
 * {
 *   id: "5e1d74e469e75a003790f618"
 *   email: "super.admin@motius.de"
 *   displayName: "Super Admin"
 *   lastName: "Admin"
 *   firstName: "Super
 *  }
 * */

interface ISession {
  id: string
  profilePicture?: string
  displayName?: string
  firstName?: string
  lastName?: string
  email: string
}
export const validateSession = (): Promise<ISession> => {
  return new Promise((resolve, reject) => {
    window
      .fetch(getValidateSessionUrl(), {
        headers: getHeader(),
        method: 'POST',
      } as RequestInit)
      .then(res => {
        if (res.status === 200) {
          resolve(res.json())
        } else {
          localStorage.clear()
          window.location.reload()
          reject(new Error('Could not validate session'))
        }
      })
  })
}

export const getMyOwnUserId = async (): Promise<string | undefined> => {
  try {
    const mySession = await validateSession()
    return mySession.id
  } catch {
    throw new Error('Could not validate session')
  }
}
