/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useState } from 'react'
import { useFormState } from 'react-final-form'
import {
  useCreate,
  useUpdate,
  useRedirect,
  useNotify,
  SaveButton,
  //@ts-ignore
} from 'react-admin'
import { apiService } from '../../services/api/apiService'

const CustomSaveButton = (props: { basePath: string; isCreate?: boolean }) => {
  const [isLoading, setLoading] = useState(false)
  const [create] = useCreate('newsletters')
  const formState = useFormState()
  const [update] = useUpdate('newsletters', formState.values.id)
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath, isCreate } = props

  const uploadAttachments = useCallback(
    updateId => {
      const { values } = formState
      const { icon, editIcon } = values

      return apiService.newsletter.addIcon(
        updateId,
        isCreate ? icon.rawFile : editIcon.rawFile,
      )
    },
    [formState, isCreate],
  )

  const handleClick = useCallback(() => {
    const { values, valid } = formState
    if (!valid) {
      return
    }

    setLoading(true)

    if (isCreate) {
      create(
        {
          payload: {
            data: {
              name: values.name,
              description: values.description,
              mailchimpListId: values.mailchimpListId,
              mailchimpInterestId: values.mailchimpInterestId,
              active: values.active,
            },
          },
        },
        {
          onSuccess: ({ data }: any) => {
            if (values.icon && values.icon.rawFile) {
              uploadAttachments(data.id).then(() => {
                setLoading(false)
                notify('ra.notification.created', 'info', { smart_count: 1 })
                redirectTo(basePath)
              })
            } else {
              notify('ra.notification.created', 'info', { smart_count: 1 })
              redirectTo(basePath)
            }
          },
          onFailure: () => {
            notify('Could not create newsletter', 'warning')
            setLoading(false)
          },
        },
      )
    } else {
      update(
        {
          payload: {
            data: {
              name: values.name,
              description: values.description,
              mailchimpListId: values.mailchimpListId,
              mailchimpInterestId: values.mailchimpInterestId,
              active: values.active,
            },
          },
        },
        {
          onSuccess: ({ data }: any) => {
            if (values.editIcon && values.editIcon.rawFile) {
              uploadAttachments(values.id).then(() => {
                setLoading(false)
                notify('ra.notification.updated', 'info', { smart_count: 1 })
                redirectTo(basePath)
              })
            } else {
              notify('ra.notification.updated', 'info', { smart_count: 1 })
              redirectTo(basePath)
            }
          },
          onFailure: () => {
            notify('Could not update newsletter', 'warning')
            setLoading(false)
          },
        },
      )
    }
  }, [
    formState,
    uploadAttachments,
    create,
    redirectTo,
    notify,
    basePath,
    isCreate,
    update,
  ])

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      disabled={isLoading}
    />
  )
}

export default CustomSaveButton
