import { axiosIdentity } from './axiosIdentity'
import { AxiosResponse } from 'axios'

interface ICitiesParameters {
  searchString: string
  matchStart?: boolean
  resultSize?: number
  sortType?: 'population' | 'name'
}

export interface ICountry {
  code: string
  en: string
  ar: string
  dialCodes: string[]
}

export interface ICity {
  name: string
  arabname?: string
  // eslint-disable-next-line camelcase
  country_code: string
  country: {
    id: number
    code: string
    en: string
    ar: string
  }
  population: string
}
export const getCities = ({
  searchString,
  matchStart = true,
  resultSize = 10,
  sortType = 'population',
}: ICitiesParameters): Promise<AxiosResponse<ICity[]>> => {
  return axiosIdentity.get(
    `/cities?city=${searchString}&matchStart=${matchStart}&limit=${resultSize}&sortType=${sortType}`,
  )
}

export const getCountries = () => axiosIdentity.get('/countries')
