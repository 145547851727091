import { axiosIdentity } from './axiosIdentity'
import {
  INewsletter,
  IInterest,
  IList,
  ICategory,
} from '../../models/INewsletter'
import { AxiosResponse } from 'axios'

export const getNewsletters = () => {
  return axiosIdentity
    .get(`/newsletters`)
    .then((response: AxiosResponse<INewsletter[]>) => response.data)
}

export const getLists = () => {
  return axiosIdentity
    .get(`/admin/newsletters/lists`)
    .then((response: AxiosResponse<IList[]>) => response.data)
}

export const getCategoryTree = (listId: string) => {
  return axiosIdentity
    .get(`/admin/newsletters/categoryTree?mailchimpListId=${listId}`)
    .then((response: AxiosResponse<ICategory[]>) => response.data)
}

export const getInterests = () => {
  return axiosIdentity
    .get(`/admin/newsletters/interests`)
    .then((response: AxiosResponse<IInterest[]>) => response.data)
}

export const getNewsletter = (listId: string) => {
  return axiosIdentity
    .get(`/admin/newsletters/${listId}`)
    .then((response: AxiosResponse<INewsletter>) => response.data)
}

export const updateNewsletter = (listId: string, newsletter: INewsletter) => {
  return axiosIdentity
    .patch(`/admin/newsletters/${listId}`, newsletter)
    .then((response: AxiosResponse<INewsletter>) => response.data)
}

export const addListIcon = (listId: string, logo: File) => {
  const formData = new window.FormData()
  formData.append('newsletterIcon', logo)

  return axiosIdentity
    .post(`/admin/newsletters/${listId}/icon`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data)
}

export const deleteNewsletter = (listId: string) => {
  return axiosIdentity
    .delete(`/admin/newsletters/${listId}`)
    .then((response: AxiosResponse<INewsletter>) => response.data)
}

export const createNewsletter = (newsletter: INewsletter) => {
  return axiosIdentity
    .post(`/admin/newsletters/`, newsletter)
    .then((response: AxiosResponse<INewsletter>) => response.data)
}
