import React, { useState, useEffect } from 'react'
import { OptionsType, ValueType } from 'react-select'
import AsyncSelect from 'react-select/async'
import { apiService } from '../../services/api/apiService'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { usePrevious } from '../../utils'

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      fontFamily: theme.typography.fontFamily,
      margin: '8px 0px 4px',
      width: '100%',
    },
    label: {
      fontSize: 12,
      opacity: 0.54,
    },
  }),
  { name: 'MemberSelect' },
)

export const MemberSelect = ({
  field,
  label,
}: {
  field: 'members' | 'admins'
  label: string
}) => {
  const form = useForm()
  const formState = useFormState()
  const classes = useStyles()

  const [currentValues, setCurrentValues] = useState<
    { value: string; label: string }[]
  >()

  const prevFormState = usePrevious(formState)

  useEffect(() => {
    if (prevFormState === undefined && !!formState) {
      const data = formState.values[field] ?? []
      const parsedData = data
        .filter((val: any) => !!val)
        .map((val: any) => ({
          value: val.id ?? val._id,
          label: val.email,
          _id: val.id ?? val._id,
        }))
      setCurrentValues(parsedData)
      form.change(field, parsedData)
    }
  }, [prevFormState, formState, form, field])

  const handleChange = (values: { value: string; label: string }[]) => {
    setCurrentValues(values)
    form.change(field, values)
  }

  const loadOptions = async (
    searchString: string,
    callback: (options: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    const { data } = await apiService.user.getMany(searchString)

    const filteredOptions = data.map(user => ({
      label: user.email,
      value: user._id,
      _id: user._id,
    }))
    callback(filteredOptions)
  }

  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>{label}</label>
      <AsyncSelect
        label={label}
        isMulti
        source={field}
        placeholder='Type to search...'
        value={currentValues}
        cacheOptions
        noOptionsMessage={({ inputValue }) =>
          inputValue === '' ? `Start typing to find ${field}` : 'No options'
        }
        onChange={
          handleChange as (
            value: ValueType<{ value: string; label: string }>,
          ) => void
        }
        loadOptions={loadOptions}
        defaultOptions
      />
    </div>
  )
}
