import React from 'react'

interface IProps {
  record?: {
    firstName?: string
    lastName?: string
  }
  label: string
  sortBy?: string
}

const FullNameField = ({ record = {} }: IProps) => (
  <span>{`${record.firstName || ''} ${record.lastName || ''}`}</span>
)

export default FullNameField
