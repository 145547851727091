import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  TopToolbar,
} from 'react-admin'
import CustomDeleteButton from './CustomDeleteButton'
import CustomDownloadButton from './CustomDownloadButton'

const InvitationShowActions = (props: any) => (
  <TopToolbar>
    <CustomDownloadButton {...props} />
    <CustomDeleteButton {...props} />
  </TopToolbar>
)

export const InvitationShow = (props = {}) => {
  return (
    <Show
      {...props}
      actions={<InvitationShowActions />}
      title={
        <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
          INVITATION: SHOW
        </h5>
      }
    >
      <SimpleShowLayout>
        <TextField source='fileName' />
        <DateField source='createdAt' />
        <NumberField source='accepted' />
        <NumberField source='existing' />
        <NumberField source='invited' />
      </SimpleShowLayout>
    </Show>
  )
}
