import React, { useState, useEffect } from 'react'
import { ValueType } from 'react-select'
import { apiService } from '../../services/api/apiService'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import { usePrevious } from '../../utils'

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      height: '68px',
      fontFamily: theme.typography.fontFamily,
      margin: '8px 0px 4px',
      width: '100%',
    },
    label: {
      marginBottom: 8,
      fontSize: 12,
      opacity: 0.54,
    },
  }),
  { name: 'MailchimpIdSelect' },
)

export const InterestSelect = ({ isEdit }: { isEdit?: boolean }) => {
  const form = useForm()
  const formState = useFormState()
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [currentVal, setCurrentVal] = useState<{
    value: string
    label: string
  } | null>(null)
  const classes = useStyles()

  const { mailchimpListId, mailchimpInterestId } = formState.values
  const prevInterestId = usePrevious(mailchimpInterestId)
  const prevListId = usePrevious(mailchimpListId)

  const handleChange = (value: { value: string; label: string }) => {
    setCurrentVal(value)
    form.change('name.en', value.label)
    form.change('mailchimpInterestId', value.value)
  }

  useEffect(() => {
    if (mailchimpListId) {
      setIsLoading(true)
      apiService.newsletter
        .categories(mailchimpListId)
        .then(data => {
          const mappedOptions = data.map(category => {
            return {
              options: category.interests
                .filter(
                  interest =>
                    !interest.used ||
                    (isEdit && interest.id === mailchimpInterestId),
                )
                .map(interest => ({
                  label: interest.name,
                  value: interest.id,
                })),
              label: category.title,
            }
          })

          setOptions(mappedOptions)
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => setIsLoading(false))
    }
  }, [mailchimpListId, isEdit, mailchimpInterestId])

  useEffect(() => {
    //only execute once
    if (
      mailchimpInterestId &&
      mailchimpListId &&
      !prevInterestId &&
      !prevListId
    ) {
      apiService.newsletter.categories(mailchimpListId).then(categories => {
        const interests = categories.flatMap(category => category.interests)
        const interest = interests.find(
          interest => interest.id === mailchimpInterestId,
        )
        if (interest) {
          setCurrentVal({
            label: interest.name,
            value: interest.id,
          })
        }
      })
    }
    if (mailchimpListId !== prevListId) {
      setCurrentVal(null)
      form.change('name.en', '')
    }
  }, [mailchimpInterestId, prevInterestId, mailchimpListId, prevListId, form])

  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>Interest</label>
      <Select
        isLoading={isLoading}
        isDisabled={!mailchimpListId}
        label='Interest'
        placeholder={!mailchimpListId ? 'Select a List first.' : 'Select...'}
        source='mailchimpInterestId'
        value={currentVal}
        cacheOptions
        onChange={
          handleChange as (
            value: ValueType<{ value: string; label: string }>,
          ) => void
        }
        options={options}
      />
    </div>
  )
}
