import login from './login'
import register from './register'
import {
  deleteUser,
  getUser,
  updateUser,
  getOrganisations,
  getOtp,
  getQr,
  validateTwoFactorQr,
  uploadPhoto,
  checkEmail,
  disableTfa,
  validateSession,
  getUsersAdmin,
} from './user'
import { updatePassword } from './updatePassword'
import { verifyEmail, verifyCode, resendVerifyEmail } from './verify-email'
import {
  getOrganisation,
  getOrganisationAdmin,
  getOrganisationAdmins,
  createOrganisation,
  deleteOrganisation,
  updateOrganisation,
  addOrganisationAdmin,
  addOrganisationMember,
  removeOrganisationMember,
  removeOrganisationAdmin,
  addOrganisationLogo,
  getMembers,
  decideRequest,
} from './organization'
import { logout } from './logout'
import { getCities, getCountries } from './location'
import {
  getNewsletter,
  createNewsletter,
  updateNewsletter,
  deleteNewsletter,
  addListIcon,
  getNewsletters,
  getInterests,
  getCategoryTree,
  getLists,
} from './newsletter'
import {
  createInvitation,
  deleteInvitation,
  downloadInvitation,
} from './invitation'

export const apiService = {
  auth: {
    login,
    register,
    verifyEmail,
    verifyCode,
    resendVerifyEmail,
    updatePassword,
    checkEmail,
    logout,
  },
  organisation: {
    get: getOrganisation,
    getAdmin: getOrganisationAdmin,
    getAdmins: getOrganisationAdmins,
    getMembers: getMembers,
    addMember: addOrganisationMember,
    addAdmin: addOrganisationAdmin,
    removeMember: removeOrganisationMember,
    removeAdmin: removeOrganisationAdmin,
    create: createOrganisation,
    delete: deleteOrganisation,
    update: updateOrganisation,
    addLogo: addOrganisationLogo,
    decideRequest,
  },
  user: {
    validate: validateSession,
    get: getUser,
    getMany: getUsersAdmin,
    organisations: getOrganisations,
    update: updateUser,
    uploadPhoto: uploadPhoto,
    delete: deleteUser,
    getOtp,
    getQr,
    validateTwoFactorQr,
    disableTfa,
  },
  newsletter: {
    get: getNewsletter,
    getMany: getNewsletters,
    create: createNewsletter,
    update: updateNewsletter,
    delete: deleteNewsletter,
    addIcon: addListIcon,
    interests: getInterests,
    categories: getCategoryTree,
    lists: getLists,
  },
  invitations: {
    create: createInvitation,
    delete: deleteInvitation,
    download: downloadInvitation,
  },
  location: {
    getCities,
    getCountries,
  },
}
