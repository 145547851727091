import React from 'react'
import { List, Datagrid, TextField, BooleanField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  cell: {
    maxWidth: 200,
    wordWrap: 'break-word',
  },
}))

export const SubscriptionList = (props = {}) => {
  const classes = useStyles()
  return (
    <List
      title={
        <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
          NEWSLETTERS
        </h5>
      }
      {...props}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name.en' label='Name: English' />
        <TextField source='name.ar' label='Name: Arabic' />
        <TextField
          cellClassName={classes.cell}
          source='description.en'
          label='Description: English'
        />
        <TextField
          cellClassName={classes.cell}
          source='description.ar'
          label='Description: Arabic'
        />
        <TextField source='mailchimpInterestId' label='Mailchimp Interest ID' />
        {/*<ImageField className='newsletterIcon' source='icon' title='Icon' />*/}
        <BooleanField source='active' />
      </Datagrid>
    </List>
  )
}
