/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useState } from 'react'
import { useFormState } from 'react-final-form'
import {
  useCreate,
  useUpdate,
  useRedirect,
  useNotify,
  SaveButton,
  //@ts-ignore
} from 'react-admin'

const CustomSaveButton = (props: { basePath: string; isCreate?: boolean }) => {
  const [isLoading, setLoading] = useState(false)
  const [create] = useCreate('organisations')
  const formState = useFormState()
  const [update] = useUpdate('organisations', formState.values.id)
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath, isCreate } = props

  const handleClick = useCallback(() => {
    const { values, valid } = formState
    if (!valid) {
      return
    }

    setLoading(true)

    if (isCreate) {
      create(
        {
          payload: {
            data: {
              ...values,
              members:
                values.members?.map(
                  (value: { value: string; label: string }) => value.value,
                ) ?? [],
              admins:
                values.admins?.map(
                  (value: { value: string; label: string }) => value.value,
                ) ?? [],
            },
          },
        },
        {
          onSuccess: ({ data }: any) => {
            notify('ra.notification.created', 'info', { smart_count: 1 })
            redirectTo(basePath)
          },
          onFailure: () => {
            notify('Could not create organisation', 'warning')
            setLoading(false)
          },
        },
      )
    } else {
      update(
        {
          payload: {
            data: {
              ...values,
              members:
                values.members
                  ?.filter((v: unknown | null) => !!v)
                  .map(
                    (
                      value:
                        | { value: string; label: string; _id?: string }
                        | string,
                    ) =>
                      typeof value === 'string'
                        ? value
                        : value._id ?? value.value,
                  ) ?? [],
              admins:
                values.admins
                  ?.filter((v: unknown | null) => !!v)
                  .map(
                    (
                      value:
                        | { value: string; label: string; _id?: string }
                        | string,
                    ) =>
                      typeof value === 'string'
                        ? value
                        : value._id ?? value.value,
                  ) ?? [],
            },
          },
        },
        {
          onSuccess: ({ data }: any) => {
            notify('ra.notification.updated', 'info', { smart_count: 1 })
            redirectTo(basePath)
          },
          onFailure: () => {
            notify('Could not update organisation', 'warning')
            setLoading(false)
          },
        },
      )
    }
  }, [formState, create, redirectTo, notify, basePath, isCreate, update])

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      disabled={isLoading}
    />
  )
}

export default CustomSaveButton
