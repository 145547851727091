import axios, { AxiosInstance } from 'axios'
import { loadToken } from '../TokenService'

const createAxiosInstance = (isAuth: boolean): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_AUTH_URL,
    timeout: 12000,
  })
  instance.interceptors.request.use(
    config => {
      if (isAuth) {
        config.headers.Authorization = loadToken()
      }
      return { ...config }
    },

    error => {
      return Promise.reject(error)
    },
  )
  return instance
}

export const axiosAuth = createAxiosInstance(true)
export const axiosNoAuth = createAxiosInstance(false)
