import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import { useNotify } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { axiosIdentity } from '../../services/api/axiosIdentity'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    minWidth: 400,
    minHeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    margin: '10px 0px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    margin: '0px 5px',
  },
  overlayButton: {
    position: 'relative',
    top: '-6px',
  },
}))

const BulkActionButtons = (props: any) => {
  const { selectedIds } = props
  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const handleSend = () => {
    axiosIdentity
      .post('/admin/users/notify', {
        users: selectedIds,
        subject,
        message: content,
      })
      .then(res => notify('Email successfully sent!'))
      .catch(e => notify('Error:' + e))
      .finally(() => handleClose())
  }

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSubject(e.target.value)
  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setContent(e.target.value)
  const classes = useStyles()

  return (
    <>
      <Button
        className={classes.overlayButton}
        onClick={handleOpen}
        variant='contained'
      >
        Send Email
      </Button>
      <Modal
        open={open}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant='h4' gutterBottom>
              Send Email
            </Typography>
            <Typography variant='subtitle1'>
              Currently selected: {selectedIds.length} Users
            </Typography>
            <div className={classes.container}>
              <TextField
                className={classes.input}
                value={subject}
                onChange={handleSubjectChange}
                id='outlined-basic'
                label='Subject'
                variant='outlined'
              />
              <TextField
                className={classes.input}
                id='outlined-multiline-flexible'
                label='Content'
                multiline
                value={content}
                onChange={handleContentChange}
                rows={4}
                rowsMax={8}
                variant='outlined'
              />
            </div>
            <div className={classes.footer}>
              <Button
                onClick={handleClose}
                className={classes.button}
                variant='contained'
              >
                Cancel
              </Button>
              <Button
                onClick={handleSend}
                className={classes.button}
                variant='contained'
                color='primary'
              >
                Send
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default BulkActionButtons
