/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  downloadCSV,
  TextInput,
  FileField,
  DateField,
  NumberField,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import { IOrganisation } from '../../models/IOrganisation'
import { IMember } from '../../models/IUser'
import ListActions from '../../components/ListActions'

const exporter = (invites: any[]) => {
  const postsForExport = invites.map(invite => {
    const { id, _id, creator, ...inviteexport } = invite // omit id
    const removeId = (member: IMember) => {
      const { id, ...creatorWithoutId } = member
      return creatorWithoutId
    }
    const creatorWithoutId = removeId(creator)
    return {
      ...inviteexport,
      creator: creatorWithoutId,
    }
  })
  jsonExport(postsForExport, { rowDelimiter: ';' }, (err: any, csv: any) => {
    downloadCSV(csv, 'invites') // download as 'posts.csv` file
  })
}

export const InvitationList = (props = {}) => (
  <List
    actions={<ListActions />}
    title={
      <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
        INVITATIONS
      </h5>
    }
    {...props}
    perPage={25}
    exporter={exporter}
    bulkActionButtons={false}
  >
    <Datagrid rowClick='show'>
      <TextField source='fileName' />
      <DateField source='createdAt' />
      <NumberField soruce='invited' />
      <NumberField soruce='accepted' />
      <NumberField soruce='existing' />
    </Datagrid>
  </List>
)
