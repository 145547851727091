import React from 'react'
// @ts-ignore
import { Toolbar } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import DeleteWithConfirmButton from '../../components/DeleteButton'
import CustomSaveButton from './SaveButton'

const useStyles = makeStyles({
  defaultToolbar: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolBar = ({ isCreate, ...props }: any) => {
  const classes = useStyles()
  return (
    <Toolbar {...props}>
      <div className={classes.defaultToolbar}>
        <CustomSaveButton isCreate={isCreate} {...props} />
        <DeleteWithConfirmButton
          entity='Client App'
          {...props}
          undoable={false}
        />
      </div>
    </Toolbar>
  )
}

export default CustomToolBar
