import React, { useEffect, useState } from 'react'
import './App.css'
import HomePage from './pages/home/Home'
import Login from './pages/login/Login'
import {
  defaultCookieOptions,
  loadToken,
  storeToken,
} from './services/TokenService'
import authProvider from './pages/home/authProvider'
import Cookies from 'js-cookie'

const sessionTokenKey = 'session_token'

function App() {
  const [token, setToken] = useState(loadToken())

  useEffect(() => {
    const sessionToken = Cookies.get(sessionTokenKey)
    if (sessionToken?.length) {
      authProvider
        .login({ sessionToken })
        .then(() => {
          window.location.replace('/#/users')
        })
        .finally(() => {
          Cookies.remove(sessionToken, defaultCookieOptions)
        })
    }
  })

  useEffect(() => {
    const sessionToken = Cookies.get(sessionTokenKey)
    if (sessionToken?.length) {
      storeToken(sessionToken)
      setToken(sessionToken)
      window.setTimeout(() => {
        window.history.replaceState({}, document.title, '/')
      })
    }
  }, [])

  const hasToken = token && token.length > 0
  return hasToken ? <HomePage /> : <Login />
}

export default App
