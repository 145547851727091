export default [
  {
    id: 'm',
    name: 'Male',
  },
  {
    id: 'f',
    name: 'Female',
  },
]
