import React from 'react'
import { StyleRulesCallback, Theme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core'

const appId = 'admin'
const next = window.location.origin
const FID_LOGIN_PAGE = process.env.REACT_APP_AUTHORIZE_PAGE
const CONNECT_URL = `${FID_LOGIN_PAGE}?app_id=${appId}&next=${next}`

// @ts-ignore
const styles: StyleRulesCallback<any, any> = (theme: Theme) => ({
  buttonWrapper: {
    width: '96vw',
    height: '95vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  button: {
    outline: 'none',
    backgroundColor: 'white',
    color: 'black',
    padding: '25px 38px',
    border: '2px solid black',
    fontSize: '29px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111',
      color: 'white',
      transition: 'all 0.4s',
    },
    fontFamily: 'Avenir-Book,sans-serif',
  },
  imageWrapper: {
    padding: '20px',
  },
  logo: {
    maxWidth: '350px',
    height: 'auto',
  },
})

const LoginPage = withStyles(styles)(({ classes }: any) => {
  const connect = () => {
    document.location.assign(CONNECT_URL)
  }
  return (
    <>
      <div className={classes.buttonWrapper}>
        <div className={classes.imageWrapper}>
          <img className={classes.logo} src='FID-Logo-01.png' alt='' />
        </div>
        <button className={classes.button} type='button' onClick={connect}>
          Connect with FUTURE ID
        </button>
      </div>
    </>
  )
})

export default LoginPage
