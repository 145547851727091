import React from 'react'
// @ts-ignore
import { Toolbar } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import DeleteWithConfirmButton from '../../components/DeleteButton'
import CustomSaveButton from './CustomSaveButton'

const useStyles = makeStyles({
  defaultToolbar: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolBar = (props: any) => {
  const classes = useStyles()
  return (
    <Toolbar {...props}>
      <div className={classes.defaultToolbar}>
        <CustomSaveButton {...props} isCreate={props.isCreate} />
        <DeleteWithConfirmButton entity='Organisation' {...props} />
      </div>
    </Toolbar>
  )
}

export default CustomToolBar
