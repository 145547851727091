import React from 'react'
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin'

export const UserCreate = (props = {}) => (
  <Create
    {...props}
    title={
      <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
        USER: CREATE
      </h5>
    }
  >
    <SimpleForm variant='standard'>
      <TextInput source='email' />
      <TextInput source='password' />
      <BooleanInput source='validated' />
    </SimpleForm>
  </Create>
)
