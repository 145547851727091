/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  downloadCSV,
  TextInput,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import ListActions from '../../components/ListActions'
import { IClientApp } from '../../models/IClientApp'

const exporter = (clientApps: IClientApp[]) => {
  const postsForExport = clientApps.map(app => {
    const { id, ...exportApp } = app // omit id
    return {
      ...exportApp,
    }
  })
  jsonExport(postsForExport, { rowDelimiter: ';' }, (err: any, csv: any) => {
    downloadCSV(csv, 'clientApps')
  })
}

const ClientAppFilter = (props = {}) => (
  <Filter {...props} variant='standard'>
    <TextInput label='Platform Name' source='name' alwaysOn />
  </Filter>
)
export const ClientAppList = (props = {}) => (
  <List
    actions={<ListActions />}
    title={
      <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
        CLIENT APPS
      </h5>
    }
    {...props}
    exporter={exporter}
    perPage={25}
    bulkActionButtons={false}
    filters={<ClientAppFilter />}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <TextField label='Contact Name' source='contact.name' />
      <TextField label='Contact Email' source='contact.email' />
    </Datagrid>
  </List>
)
