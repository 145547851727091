import { loadToken, storeToken } from '../../services/TokenService'

export default {
  login: (params: any) => {
    return new Promise((resolve, reject) => {
      storeToken(params.sessionToken)
      resolve('')
    })
  },
  logout: (params: any) => Promise.resolve(),
  checkAuth: (params: any) => {
    return new Promise((resolve, reject) => {
      loadToken() ? resolve('') : reject(new Error('Unauthorized'))
    })
  },
  checkError: (error: any) => {
    console.error(error)
    Promise.resolve('')
  },
  getPermissions: (params: any) => Promise.resolve(),
}
