import { axiosNoAuth } from './axiosAuth'

const login = (email: string, password: string, token?: string) => {
  const payload: { email: string; password: string; token?: string } = {
    email,
    password,
  }
  if (token) payload.token = token
  return axiosNoAuth
    .post('/auth/login', { ...payload })
    .then(response => {
      if (response && response.data) {
        return response
      }
    })
    .catch(error => {
      throw error.response
    })
}

export default login
