import React, { useState, useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      fontFamily: theme.typography.fontFamily,
      margin: '8px 0px 4px 0px',
      width: '100%',
      height: '68px',
    },
    textField: {
      width: '100%',
    },
  }),
  { name: 'LinkedinInput' },
)

export const LinkedinInput = () => {
  const form = useForm()
  const classes = useStyles()
  const formState = useFormState()
  const [currentVal, setCurrentVal] = useState(
    formState.values.socialMedia && formState.values.socialMedia.length
      ? formState.values.socialMedia[0].url
      : '',
  )

  useEffect(() => {
    form.change('socialMedia', [{ type: 'LinkedIn', url: currentVal }])
  }, [currentVal, form])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentVal(event.target.value)
  }

  return (
    <div className={classes.wrapper}>
      <TextField
        className={classes.textField}
        label='LinkedIn'
        value={currentVal}
        onChange={handleChange}
      />
    </div>
  )
}
