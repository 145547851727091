import React from 'react'
import { useFormState } from 'react-final-form'
import { IUser } from '../../models/IUser'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { apiService } from '../../services/api/apiService'

export const PendingSelectField = ({
  field,
  label,
}: {
  field: 'pendingInvitations' | 'pendingRequests'
  label: string
}) => {
  const formState = useFormState()
  const { values } = formState
  const choices =
    values?.[field]
      ?.filter(({ user }: { user: IUser }) => !!user)
      .map(({ _id, user }: { _id: string; user: IUser }) => ({
        id: _id,
        name: user.email,
      })) ?? []

  const handleAdd = (index: number) => async () => {
    await apiService.organisation.decideRequest(
      values.pendingRequests[index]._id,
      true,
      false,
    )
    window.location.reload()
  }

  const handleRemove = (index: number) => async () => {
    await apiService.organisation.decideRequest(
      values.pendingRequests[index]._id,
      false,
      false,
    )
    window.location.reload()
  }
  return (
    <div style={{
      margin: '8px 0 4px',
    }}>
      <label
        style={{
          color: 'rgba(0,0,0,0.87)',
          fontFamily: 'Avenir-book, sans-serif',
          fontSize: 12,
          opacity: 0.54,
        }}
      >
        {label}
      </label>
      <br />
      {choices.map((choice: any, index: number) => (
        <div>
          <Chip
            size='small'
            variant='outlined'
            style={{ margin: 5, padding: 5, height: 40 }}
            label={
              <>
                {choice.name}
                <IconButton
                  onClick={handleRemove(index)}
                  style={{ marginLeft: 5 }}
                  size='small'
                >
                  <ClearIcon />
                </IconButton>
                <IconButton onClick={handleAdd(index)} size='small'>
                  <DoneIcon />
                </IconButton>
              </>
            }
            key={choice.id}
          />
        </div>
      ))}
      {choices.length === 0 && (
        <span
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '0.8125rem',
            fontFamily: 'Avenir-book,sans-serif',
          }}
        >
          No Pending Requests
        </span>
      )}
    </div>
  )
}
