"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const query_string_1 = require("query-string");
const { GET_LIST, GET_ONE, GET_MANY, CREATE, UPDATE, DELETE, DELETE_MANY, } = require('react-admin');
/**
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
exports.default = (apiUrl, token) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        const returnObj = {
            url: '',
            options: {},
        };
        returnObj.options.headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        });
        const { pagination, filter, sort, id, ids, data } = params;
        switch (type) {
            case GET_LIST: {
                returnObj.url = `${apiUrl}/${resource}?page=${((pagination === null || pagination === void 0 ? void 0 : pagination.page) || 1) -
                    1}&pageSize=${pagination === null || pagination === void 0 ? void 0 : pagination.perPage}&sort=${(sort === null || sort === void 0 ? void 0 : sort.order) === 'DESC' ? '-' + sort.field : sort === null || sort === void 0 ? void 0 : sort.field}&search=${encodeURIComponent(JSON.stringify(filter))}`;
                break;
            }
            case GET_ONE:
                returnObj.url = `${apiUrl}/${resource}/${id}`;
                break;
            case GET_MANY: {
                const query = {
                    filter: JSON.stringify({ id: ids }),
                };
                returnObj.url = `${apiUrl}/${resource}?${(0, query_string_1.stringify)(query)}`;
                break;
            }
            case UPDATE:
                returnObj.url = `${apiUrl}/${resource}/${id}`;
                if (data) {
                    // filter out description fields (deprecated) and tags for organisations
                    const { fullDescription, shortDescription, tags } = data, rest = __rest(data, ["fullDescription", "shortDescription", "tags"]);
                    const getReturnData = () => {
                        if (tags === null || tags === void 0 ? void 0 : tags.length) {
                            return Object.assign({ _id: id, tags }, rest);
                        }
                        else {
                            return Object.assign({ _id: id }, rest);
                        }
                    };
                    const returnData = getReturnData();
                    returnObj.options.method = 'PATCH';
                    returnObj.options.body = JSON.stringify(returnData);
                }
                break;
            case CREATE:
                returnObj.url = `${apiUrl}/${resource}`;
                returnObj.options.method = 'POST';
                returnObj.options.body = JSON.stringify(data);
                break;
            case DELETE:
                returnObj.url = `${apiUrl}/${resource}/${id}`;
                returnObj.options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return returnObj;
    };
    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, params) => {
        var _a, _b, _c, _d, _e;
        const json = response;
        switch (type) {
            case GET_LIST:
                const { data } = response;
                const finalData = data.map(val => val._id ? Object.assign(Object.assign({}, val), { id: val._id }) : val);
                return {
                    data: finalData,
                    total: (_a = json === null || json === void 0 ? void 0 : json.totalItems) !== null && _a !== void 0 ? _a : finalData.length,
                };
            case GET_ONE: {
                const id = json._id || json.id;
                const validRedirectUris = (_b = json.validRedirectUris) === null || _b === void 0 ? void 0 : _b.map(val => ({
                    value: val,
                }));
                const userScope = (_c = json.validScopes) === null || _c === void 0 ? void 0 : _c.filter((scope) => scope.includes('user'));
                const userScopeValues = userScope === null || userScope === void 0 ? void 0 : userScope.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.split('.')[1]]: true })), {});
                const organisationScope = (_d = json.validScopes) === null || _d === void 0 ? void 0 : _d.filter((scope) => scope.includes('organisation'));
                const organisationScopeValues = organisationScope === null || organisationScope === void 0 ? void 0 : organisationScope.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.split('.')[1]]: true })), {});
                const openidScope = (_e = json.validScopes) === null || _e === void 0 ? void 0 : _e.filter((scope) => scope.includes('openid'));
                const openidScopeValues = openidScope === null || openidScope === void 0 ? void 0 : openidScope.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.split('.')[1]]: true })), {});
                const elem = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ id }, json), (!!validRedirectUris && { validRedirectUris })), ((!!userScopeValues || json.user) && {
                    user: userScopeValues !== null && userScopeValues !== void 0 ? userScopeValues : json.user,
                })), (openidScopeValues && { openid: openidScopeValues })), ((!!organisationScopeValues || json.organisation) && {
                    organisation: organisationScopeValues !== null && organisationScopeValues !== void 0 ? organisationScopeValues : json.organisation,
                }));
                return { data: elem };
            }
            case CREATE: {
                const data = json;
                return { data: Object.assign(Object.assign({}, data), { id: data._id ? data._id : data.id }) };
            }
            case DELETE_MANY: {
                return { data: json || [] };
            }
            case UPDATE: {
                const id = json._id;
                // the user object contains a lot of attributes
                // its not worth it to define the whole type for
                // this tiny one files
                return {
                    data: Object.assign(Object.assign({}, json), { id }),
                };
            }
            default:
                return { data: json };
        }
    };
    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        const { url, options } = convertDataRequestToHTTP(type, resource, params);
        return fetch(url, options)
            .then(res => res.json())
            .then(response => convertHTTPResponse(response, type, params));
    };
};
