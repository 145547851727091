import React from 'react'
import { useFormState } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

const ImgField = () => {
  const formState = useFormState()
  const src = formState.values.profilePicture

  if (!src) {
    return null
  }

  return (
    <FormControl variant='standard' margin='dense' size='small'>
      <InputLabel htmlFor='profilePicture' filled shrink>
        Profile Picture
      </InputLabel>
      <div>
        <img
          id='profilePicture'
          src={src}
          alt='profile'
          style={{ maxWidth: 200, maxHeight: 200, marginTop: 20 }}
        />
      </div>
      <FormHelperText>&#8203;</FormHelperText>
    </FormControl>
  )
}

export default ImgField
