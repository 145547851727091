import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2775bb',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Avenir-book', 'sans-serif'].join(','),
  },
})

export default theme
