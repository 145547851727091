import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Create,
  TextInput,
  TabbedForm,
  FormTab,
  NumberInput,
} from 'react-admin'
import CustomToolBar from './CustomEditToolBar'
import RedirectUrls from './RedirectUrls'
import Scopes from './Scopes'

const useStyles = makeStyles(
  theme => ({
    formWrapper: {
      width: '30rem',
      maxWidth: '90%',
      display: 'flex',
      'flex-direction': 'column',
    },
    scopeWrapper: {
      width: '100%',
    },
  }),
  { name: 'RaLoginForm' },
)

export const ClientAppCreate = (props = {}) => {
  const classes = useStyles()

  return (
    <Create
      {...props}
      title={
        <h5 style={{ fontSize: '14px', fontWeight: 500, margin: 0 }}>
          CLIENTAPP: CREATE
        </h5>
      }
    >
      <TabbedForm variant='standard' toolbar={<CustomToolBar isCreate />}>
        <FormTab label='General'>
          <div className={classes.formWrapper}>
            <TextInput source='name' fullWidth variant='standard' />
            <TextInput
              label='Contact Name'
              source='contact.name'
              fullWidth
              variant='standard'
            />
            <TextInput
              label='Contact Email'
              source='contact.email'
              fullWidth
              variant='standard'
            />
            <NumberInput
              label='Contact Phone Number'
              source='contact.phone'
              fullWidth
              variant='standard'
            />
          </div>
        </FormTab>
        <FormTab label='Settings'>
          <div className={classes.formWrapper}>
            <RedirectUrls />
          </div>
        </FormTab>
        <FormTab label='Scopes'>
          <div className={classes.scopeWrapper}>
            <Scopes />
          </div>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
