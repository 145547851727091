import React, { useState } from 'react'
import { OptionsType, ValueType } from 'react-select'
import AsyncSelect from 'react-select/async'
import { apiService } from '../../services/api/apiService'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { ICity } from '../../services/api/location'

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      height: '68px',
      fontFamily: theme.typography.fontFamily,
      margin: '8px 0px 4px',
      width: '100%',
    },
    label: {
      fontSize: 12,
      opacity: 0.54,
    },
  }),
  { name: 'LocationSelect' },
)

export const LocationSelect = () => {
  const form = useForm()
  const formState = useFormState()
  const [currentVal, setCurrentVal] = useState({
    value: formState.values.location,
    label: formState.values.location,
  })
  const classes = useStyles()

  const handleChange = (value: { value: string; label: string }) => {
    setCurrentVal(value)
    form.change('location', value.value)
  }

  const loadOptions = async (
    searchString: string,
    callback: (options: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    const { data } = await apiService.location.getCities({ searchString })

    const filteredOptions = data.map((city: ICity) => {
      const { name, country } = city
      const prefixedCity = `${name}, ${country.en || ''}`
      return {
        value: prefixedCity,
        label: prefixedCity,
      }
    })
    callback(filteredOptions)
  }

  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>Location</label>
      <AsyncSelect
        label='Location'
        source='location'
        placeholder='Type to search...'
        value={currentVal}
        cacheOptions
        noOptionsMessage={({ inputValue }) =>
          inputValue === '' ? 'Start typing to find cities' : 'No options'
        }
        onChange={
          handleChange as (
            value: ValueType<{ value: string; label: string }>,
          ) => void
        }
        loadOptions={loadOptions}
        defaultOptions
      />
    </div>
  )
}
