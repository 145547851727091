import React, { useState, useEffect } from 'react'
import { apiService } from '../../services/api/apiService'
import { SelectInput } from 'react-admin'
import { ICountry } from '../../services/api/location'

export const NationalitySelect = (props: any) => {
  const [choices, setChoices] = useState([])

  useEffect(() => {
    apiService.location.getCountries().then(({ data }) => {
      setChoices(
        data.map((country: ICountry) => ({
          id: country.code,
          name: country.en,
        })),
      )
    })
  }, [])

  return (
    <SelectInput
      label='Nationality'
      variant='standard'
      source='nationality'
      choices={choices}
      {...props}
    />
  )
}
