/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useState } from 'react'
import { useFormState } from 'react-final-form'
import {
  useRedirect,
  useNotify,
  SaveButton,
  //@ts-ignore
} from 'react-admin'
import { apiService } from '../../services/api/apiService'

const CustomSaveButton = (props: { basePath: string }) => {
  const [isLoading, setLoading] = useState(false)
  const formState = useFormState()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath } = props
  const { values, valid } = formState

  const uploadFile = useCallback(
    updateId => {
      const { file } = values
      if (file) {
        return apiService.invitations.create(file.rawFile)
      } else {
        throw Error('invalid file')
      }
    },
    [values],
  )

  const handleClick = useCallback(() => {
    if (!valid) {
      return
    }

    setLoading(true)

    uploadFile(values.file)
      .then(() => {
        setLoading(false)
        notify('ra.notification.created', 'info', { smart_count: 1 })
        redirectTo(basePath)
      })
      .catch(err => {
        notify('Could not create invitation', 'warning')
        setLoading(false)
      })
  }, [values, valid, uploadFile, redirectTo, notify, basePath])

  return (
    <SaveButton
      label='Send Invitations'
      {...props}
      handleSubmitWithRedirect={handleClick}
      disabled={isLoading}
    />
  )
}

export default CustomSaveButton
