import React from 'react'
import { BooleanInput } from 'react-admin'
import { userScopes, organisationScopes, openIdScopes } from '../../scopes'
import { Typography } from '@material-ui/core'

const Scopes = () => {
  const split = (val: string) => val.split('.')[1]
  const mappedUserScopes = userScopes.map(split)
  const mappedOrganisationScopes = organisationScopes.map(split)
  const mappedOpenIDScopes = openIdScopes.map(split)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: '2rem',
      }}
    >
      <div>
        <Typography variant='h5'>User Scopes</Typography>

        {mappedUserScopes.map(field => {
          return (
            <BooleanInput key={field} label={field} source={`user.${field}`} />
          )
        })}
      </div>
      <div>
        <Typography variant='h5'>Organisation Scopes</Typography>
        {mappedOrganisationScopes.map(field => {
          return (
            <BooleanInput
              key={field}
              label={field}
              source={`organisation.${field}`}
            />
          )
        })}
      </div>
      <div>
        <Typography variant='h5'>OpenID Scopes</Typography>
        {mappedOpenIDScopes.map(field => {
          return (
            <BooleanInput
              key={field}
              label={field}
              source={`openid.${field}`}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Scopes
