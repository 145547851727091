const userScopes = [
  'user.displayName',
  'user.profilePicture',
  'user.email',
  'user.lastName',
  'user.firstName',
  'user.preferredLanguage',
  'user.location',
  'user.title',
  'user.education',
  'user.tags',
  'user.mobileNumber',
  'user.dob',
  'user.nationality',
  'user.headline',
  'user.bio',
  'user.socialMedia',
  'user.gender',
  'user.createdAt',
  'user.updatedAt',
]
const organisationScopes = [
  'organisation.logo',
  'organisation.name',
  'organisation.description',
  'organisation.tags',
  'organisation.website',
  'organisation.location',
  'organisation.incorporateDate',
  'organisation.coreBusiness',
  'organisation.industrySector',
  'organisation.mainTechnology',
  'organisation.fullTimeEmployees',
  'organisation.socialMedia',
  'organisation.funding',
  'organisation.marketStage',
  'organisation.createdAt',
  'organisation.updatedAt',
]
const openIdScopes = ['openid.profile', 'openid.email']

export { userScopes, organisationScopes, openIdScopes }
