import React from 'react'
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
} from 'react-admin'

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={Infinity}
    />
    {/* Add your custom actions */}
  </TopToolbar>
)

export default ListActions
