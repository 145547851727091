import axios, { AxiosInstance } from 'axios'
import { loadToken } from '../TokenService'

const createAxiosInstance = (isAuth: boolean, config?: any): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_PROVIDER_URL,
    timeout: 15000,
    headers: { ...config },
  })
  instance.interceptors.request.use(
    (config: any) => {
      if (isAuth) {
        config.headers.Authorization = loadToken()
      }
      return { ...config }
    },
    (error: any) => {
      return Promise.reject(error)
    },
  )
  return instance
}

export const axiosIdentity = createAxiosInstance(true)
export const axiosAuthWithConfig = (config: any) =>
  createAxiosInstance(true, config)
