const TOKEN = 'token'

export const storeToken = (token: string) => {
  localStorage.setItem(TOKEN, token)
}

export const loadToken = () => {
  return localStorage.getItem(TOKEN)
}

export const logout = () => {
  localStorage.removeItem(TOKEN)
}

export const defaultCookieOptions = {
  domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
  expires: 60,
}
