import React from 'react'
// @ts-ignore
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  button: {
    color: 'white',
  },
  spacer: {
    flex: 1,
  },
}

// @ts-ignore
const CustomAppBar = withStyles(styles)(({ classes, ...props }) => {
  const logout = () => {
    localStorage.removeItem('token')
    window.location.replace('/')
  }
  return (
    <AppBar {...props} userMenu={<></>}>
      <Typography
        variant='h5'
        color='inherit'
        className={classes.title}
        id='react-admin-title'
      />
      <span className={classes.spacer} />
      <Button className={classes.button} onClick={logout}>
        Logout
      </Button>
    </AppBar>
  )
})

export default CustomAppBar
