import { axiosIdentity } from './axiosIdentity'

export const createInvitation = (file: File) => {
  const formData = new window.FormData()
  formData.append('csv', file)

  return axiosIdentity
    .post(`/admin/invitations`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)
}

export const deleteInvitation = (id: string) => {
  return axiosIdentity
    .delete(`/admin/invitations/${id}`)
    .then((response: any) => response.data)
}

export const downloadInvitation = (id: string) => {
  return axiosIdentity
    .get(`/admin/invitations/${id}/csv`)
    .then((response: any) => response.data)
}
