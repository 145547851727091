import React from 'react'
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin'

export default () => {
  return (
    <ArrayInput source='validRedirectUris' variant='standard'>
      <SimpleFormIterator>
        <TextInput variant='standard' source='value' label='Redirect Uri' />
      </SimpleFormIterator>
    </ArrayInput>
  )
}
